export const BenefitObjects = [
  {
    id: 1,
    heading: 'Logistics Explorer',
    // description: `Display your rates to thousands of shippers online via <a class='new-link' href='https://www.searates.com'>SeaRates.com</a>.`,
    description: `The best sea freight calculator for your website.`,
    icon: `
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
              <g clip-path="url(#clip0_7728_12730)">
                <g filter="url(#filter0_ii_7728_12730)">
                  <path d="M-0.0542603 15.9965C-0.0542603 10.396 -0.0542603 7.59575 1.03567 5.45663C1.99441 3.57501 3.52421 2.0452 5.40583 1.08647C7.54495 -0.00346375 10.3452 -0.00346375 15.9457 -0.00346375H31.9457C37.5463 -0.00346375 40.3465 -0.00346375 42.4856 1.08647C44.3673 2.0452 45.8971 3.57501 46.8558 5.45663C47.9457 7.59575 47.9457 10.396 47.9457 15.9965V31.9965C47.9457 37.5971 47.9457 40.3973 46.8558 42.5364C45.8971 44.4181 44.3673 45.9479 42.4856 46.9066C40.3465 47.9965 37.5463 47.9965 31.9457 47.9965H15.9457C10.3452 47.9965 7.54495 47.9965 5.40583 46.9066C3.52421 45.9479 1.99441 44.4181 1.03567 42.5364C-0.0542603 40.3973 -0.0542603 37.5971 -0.0542603 31.9965V15.9965Z" fill="url(#paint0_linear_7728_12730)"/>
                </g>
                <path d="M23.1728 31.19L14.8352 17.0107C14.0424 16.7636 8.80284 17.4826 14.7556 28.9037L19.1732 37.2442C19.2841 37.4538 19.5841 37.4596 19.7015 37.2545L23.1728 31.19Z" fill="url(#paint1_linear_7728_12730)"/>
                <path d="M31.5833 16.8573L23.1542 31.1562C23.3345 31.9596 26.5831 36.0928 33.5966 25.278L38.6859 17.3155C38.8131 17.1165 38.6701 16.8573 38.4331 16.8573L31.5833 16.8573Z" fill="url(#paint2_linear_7728_12730)"/>
                <path d="M14.7931 16.9922L31.6174 16.8276C32.23 16.2713 33.9362 11.5839 20.9699 10.9775L11.4707 10.5953C11.2319 10.5857 11.0783 10.8456 11.2019 11.0502L14.7931 16.9922Z" fill="url(#paint3_linear_7728_12730)"/>
                <path d="M31.6194 16.827L23.1665 31.1888L14.7905 16.9867L31.6194 16.827Z" fill="white"/>
              </g>
              <defs>
                <filter id="filter0_ii_7728_12730" x="-0.0542603" y="-8.17808" width="48" height="67.0741" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="10.8995"/>
                  <feGaussianBlur stdDeviation="5.44974"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_7728_12730"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="-8.17461"/>
                  <feGaussianBlur stdDeviation="5.44974"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.0109722 0 0 0 0 0.100067 0 0 0 0 0.329167 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="effect1_innerShadow_7728_12730" result="effect2_innerShadow_7728_12730"/>
                </filter>
                <linearGradient id="paint0_linear_7728_12730" x1="-0.0542606" y1="55.4965" x2="47.9457" y2="-21.0035" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#0056FB"/>
                  <stop offset="1" stop-color="#6F85FA"/>
                </linearGradient>
                <linearGradient id="paint1_linear_7728_12730" x1="26.6313" y1="42.3246" x2="19.0816" y2="9.79525" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint2_linear_7728_12730" x1="45.9367" y1="7.93769" x2="13.3528" y2="34.2723" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint3_linear_7728_12730" x1="4.10867" y1="6.09498" x2="34.6176" y2="21.7027" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <clipPath id="clip0_7728_12730">
                  <rect width="48" height="48" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          `,
    classIdentifier: 'dfa-promote-services',
    linkUrl: 'https://www.searates.com/integrations/logistics-explorer',
  },
  {
    id: 2,
    heading: 'Tracking System',
    description: `The ultimate cargo tracking tool that works on any desktop or mobile device.`,
    icon: `
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
              <g clip-path="url(#clip0_7728_12739)">
                <g filter="url(#filter0_ii_7728_12739)">
                  <path d="M-0.0541992 16.1719C-0.0541992 10.5868 -0.0541992 7.79429 1.03273 5.66108C1.98881 3.78466 3.51439 2.25907 5.39082 1.30299C7.52403 0.216064 10.3166 0.216064 15.9016 0.216064H31.8574C37.4425 0.216064 40.235 0.216064 42.3682 1.30299C44.2446 2.25907 45.7702 3.78466 46.7263 5.66108C47.8132 7.79429 47.8132 10.5868 47.8132 16.1719V32.1277C47.8132 37.7127 47.8132 40.5053 46.7263 42.6385C45.7702 44.5149 44.2446 46.0405 42.3682 46.9966C40.235 48.0835 37.4425 48.0835 31.8574 48.0835H15.9016C10.3166 48.0835 7.52403 48.0835 5.39082 46.9966C3.51439 46.0405 1.98881 44.5149 1.03273 42.6385C-0.0541992 40.5053 -0.0541992 37.7127 -0.0541992 32.1277V16.1719Z" fill="url(#paint0_linear_7728_12739)"/>
                </g>
                <path d="M18.6564 33.4438C18.5442 33.3279 18.5465 33.1465 18.6617 33.0337L28.9105 23.0014C29.0302 22.8842 29.2257 22.8857 29.3438 23.0047L34.4982 28.195C34.6115 28.3091 34.612 28.4897 34.4994 28.604L24.3657 38.8864C24.2457 39.0082 24.0451 39.0072 23.9259 38.8842L18.6564 33.4438Z" fill="url(#paint1_linear_7728_12739)"/>
                <path d="M16.1274 30.8395C16.0088 30.9626 15.8093 30.9646 15.6901 30.8424C15.3015 30.4438 14.4855 29.5921 13.9786 28.9573C13.423 28.2615 12.7953 27.0972 12.5791 26.6844C12.5303 26.5913 12.5374 26.4801 12.5965 26.3926L16.0508 21.2854C16.149 21.1402 16.3541 21.1084 16.4936 21.2167L21.3301 24.9714C21.4683 25.0786 21.483 25.2778 21.362 25.4035L16.1274 30.8395Z" fill="url(#paint2_linear_7728_12739)"/>
                <path d="M15.1534 30.2828C12.6368 27.8354 11.4385 24.6889 11.4385 21.5423C11.4385 18.3958 12.6368 15.2492 15.1534 12.8019C17.67 10.3546 20.9056 9.18921 24.1412 9.18921C27.3768 9.18921 30.6124 10.3546 33.1289 12.8019C35.6455 15.2492 36.8439 18.3958 36.8439 21.5423C36.8439 24.6889 35.6455 27.8354 33.1289 30.2828L24.3558 38.8956C24.2375 39.0118 24.0472 39.0095 23.9316 38.8905L21.8884 36.786C21.7734 36.6676 21.7761 36.4783 21.8944 36.3631C24.8164 33.5193 27.6879 30.6794 30.6124 27.8354C32.4099 26.0874 33.2488 23.8731 33.2488 21.5423C33.2488 19.3281 32.4099 16.9973 30.6124 15.2492C28.8148 13.5012 26.5379 12.6854 24.1412 12.6854C21.8643 12.6854 19.4675 13.5012 17.67 15.2492C15.8724 16.9973 15.0336 19.2116 15.0336 21.5423C15.0336 23.7566 15.8724 26.0874 17.67 27.8354L18.1718 28.2719C18.3007 28.384 18.3097 28.5812 18.1914 28.7046L16.1382 30.8473C16.0213 30.9693 15.8267 30.9703 15.7085 30.8496L15.1534 30.2828Z" fill="url(#paint3_linear_7728_12739)"/>
                <path d="M18.5088 27.0195C16.951 25.5045 16.2319 23.5233 16.2319 21.5422C16.2319 19.561 16.951 17.5798 18.5088 16.0648C20.0667 14.5498 22.1039 13.8506 24.1412 13.8506C26.1784 13.8506 28.2156 14.5498 29.7735 16.0648C31.3314 17.5798 32.0504 19.561 32.0504 21.5422C32.0504 23.5233 31.5704 25.5045 30.0125 27.0195L21.0637 35.5338C20.944 35.6477 20.7545 35.6429 20.6407 35.5232L18.6635 33.4437C18.5513 33.3257 18.554 33.1396 18.6697 33.025L24.1412 27.6022L27.2569 24.5722C28.0958 23.7564 28.5751 22.591 28.5751 21.5422C28.5751 20.4933 28.0958 19.3279 27.2569 18.5121C26.4181 17.6964 25.2197 17.2302 24.1412 17.2302C23.0626 17.2302 21.8643 17.6964 21.0254 18.5121C20.1865 19.3279 19.7072 20.4933 19.7072 21.5422C19.7072 22.591 20.1865 23.7564 21.0254 24.5722L21.3994 24.936C21.518 25.0514 21.5204 25.2413 21.4046 25.3597L19.3762 27.4324C19.2623 27.5488 19.0762 27.5527 18.9575 27.4412L18.5088 27.0195Z" fill="url(#paint4_linear_7728_12739)"/>
              </g>
              <defs>
                <filter id="filter0_ii_7728_12739" x="-0.0541992" y="-7.93597" width="47.8674" height="66.8889" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="10.8694"/>
                  <feGaussianBlur stdDeviation="6.79337"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.9875 0 0 0 0 0.9875 0 0 0 0 0.9875 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_7728_12739"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="-8.15204"/>
                  <feGaussianBlur stdDeviation="5.43469"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.148958 0 0 0 0 0.53694 0 0 0 0 0.6875 0 0 0 0.1 0"/>
                  <feBlend mode="normal" in2="effect1_innerShadow_7728_12739" result="effect2_innerShadow_7728_12739"/>
                </filter>
                <linearGradient id="paint0_linear_7728_12739" x1="-6.03763" y1="0.216064" x2="42.827" y2="48.0835" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#14C53B"/>
                  <stop offset="0.390625" stop-color="#11C88D"/>
                  <stop offset="1" stop-color="#0CBBBB"/>
                </linearGradient>
                <linearGradient id="paint1_linear_7728_12739" x1="20.7521" y1="36.0235" x2="32.9059" y2="27.201" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" stop-opacity="0.7"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint2_linear_7728_12739" x1="18.7953" y1="29.3988" x2="17.6749" y2="24.6682" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" stop-opacity="0.7"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint3_linear_7728_12739" x1="24.1411" y1="39.1064" x2="14.678" y2="-1.11001" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint4_linear_7728_12739" x1="16.2319" y1="47.9002" x2="18.1173" y2="-29.0836" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="0.84748" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <clipPath id="clip0_7728_12739">
                  <rect width="48" height="48" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          `,
    classIdentifier: 'dfa-digital-tools',
    linkUrl: '/benefits/digital-tools/tracking-system',
  },
  {
    id: 3,
    heading: 'Cargoes Flow',
    // description: `Access a network of freight forwarders with coverage in <span class="new-text--bold">190+</span> countries.`,
    description: `An enterprise, multi-vendor tracking tool for sea, air and land.`,
    icon: `
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
              <g clip-path="url(#clip0_7728_12766)">
                <g filter="url(#filter0_ii_7728_12766)">
                  <path d="M-0.0542822 15.9965C-0.0542822 10.396 -0.0542822 7.59575 1.03565 5.45663C1.99439 3.57501 3.52419 2.0452 5.40581 1.08647C7.54493 -0.00346375 10.3452 -0.00346375 15.9457 -0.00346375H31.9457C37.5462 -0.00346375 40.3465 -0.00346375 42.4856 1.08647C44.3672 2.0452 45.897 3.57501 46.8558 5.45663C47.9457 7.59575 47.9457 10.396 47.9457 15.9965V31.9965C47.9457 37.5971 47.9457 40.3973 46.8558 42.5364C45.897 44.4181 44.3672 45.9479 42.4856 46.9066C40.3465 47.9965 37.5462 47.9965 31.9457 47.9965H15.9457C10.3452 47.9965 7.54493 47.9965 5.40581 46.9066C3.52419 45.9479 1.99439 44.4181 1.03565 42.5364C-0.0542822 40.3973 -0.0542822 37.5971 -0.0542822 31.9965V15.9965Z" fill="url(#paint0_linear_7728_12766)"/>
                </g>
                <path d="M23.1728 31.19L14.8352 17.0107C14.0423 16.7636 8.80282 17.4826 14.7556 28.9037L19.1731 37.2442C19.2841 37.4538 19.5841 37.4596 19.7015 37.2545L23.1728 31.19Z" fill="url(#paint1_linear_7728_12766)"/>
                <path d="M31.5833 16.8573L23.1542 31.1562C23.3344 31.9596 26.5831 36.0928 33.5966 25.278L38.6859 17.3155C38.8131 17.1165 38.6701 16.8573 38.4331 16.8573L31.5833 16.8573Z" fill="url(#paint2_linear_7728_12766)"/>
                <path d="M14.7931 16.9922L31.6174 16.8276C32.23 16.2713 33.9362 11.5839 20.9699 10.9775L11.4707 10.5953C11.2319 10.5857 11.0783 10.8456 11.2019 11.0502L14.7931 16.9922Z" fill="url(#paint3_linear_7728_12766)"/>
                <path d="M31.6193 16.827L23.1664 31.1888L14.7904 16.9867L31.6193 16.827Z" fill="white"/>
                <g filter="url(#filter1_ii_7728_12766)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M31.5332 2.22939H15.5559C12.7228 2.22939 10.7321 2.23112 9.17879 2.35803C7.6515 2.48282 6.74446 2.71742 6.04308 3.07479C4.58362 3.81842 3.39703 5.005 2.6534 6.46447C2.29603 7.16584 2.06143 8.07289 1.93665 9.60017C1.80974 11.1535 1.808 13.1442 1.808 15.9773V31.9546C1.808 34.7876 1.80974 36.7784 1.93665 38.3317C2.06143 39.859 2.29603 40.766 2.6534 41.4674C3.39703 42.9269 4.58362 44.1134 6.04308 44.8571C6.74446 45.2144 7.6515 45.449 9.17879 45.5738C10.7321 45.7007 12.7228 45.7025 15.5559 45.7025H31.5332C34.3663 45.7025 36.357 45.7007 37.9103 45.5738C39.4376 45.449 40.3446 45.2144 41.046 44.8571C42.5055 44.1134 43.692 42.9269 44.4357 41.4674C44.7931 40.766 45.0277 39.859 45.1524 38.3317C45.2793 36.7784 45.2811 34.7876 45.2811 31.9546V15.9773C45.2811 13.1442 45.2793 11.1535 45.1524 9.60017C45.0277 8.07289 44.7931 7.16584 44.4357 6.46447C43.692 5.005 42.5055 3.81842 41.046 3.07479C40.3446 2.71742 39.4376 2.48282 37.9103 2.35803C36.357 2.23112 34.3663 2.22939 31.5332 2.22939ZM0.667001 5.45234C-0.421387 7.58842 -0.421387 10.3847 -0.421387 15.9773V31.9546C-0.421387 37.5471 -0.421387 40.3434 0.667001 42.4795C1.62437 44.3585 3.15201 45.8861 5.03096 46.8435C7.16704 47.9319 9.96332 47.9319 15.5559 47.9319H31.5332C37.1258 47.9319 39.922 47.9319 42.0581 46.8435C43.9371 45.8861 45.4647 44.3585 46.4221 42.4795C47.5105 40.3434 47.5105 37.5471 47.5105 31.9546V15.9773C47.5105 10.3847 47.5105 7.58842 46.4221 5.45234C45.4647 3.57339 43.9371 2.04576 42.0581 1.08839C39.922 0 37.1258 0 31.5332 0H15.5559C9.96332 0 7.16704 0 5.03096 1.08839C3.15201 2.04576 1.62437 3.57339 0.667001 5.45234Z" fill="#00AEEF"/>
                </g>
                <path d="M2 14.7818C2 10.3078 2 8.07074 2.87071 6.36188C3.63661 4.85872 4.85872 3.63661 6.36188 2.87071C8.07074 2 10.3078 2 14.7818 2H33.1557C37.6298 2 39.8668 2 41.5757 2.87071C43.0788 3.63661 44.3009 4.85872 45.0668 6.36188C45.9375 8.07074 45.9375 10.3078 45.9375 14.7818V33.1557C45.9375 37.6298 45.9375 39.8668 45.0668 41.5757C44.3009 43.0788 43.0788 44.3009 41.5757 45.0668C39.8668 45.9375 37.6298 45.9375 33.1557 45.9375H14.7818C10.3078 45.9375 8.07074 45.9375 6.36188 45.0668C4.85872 44.3009 3.63661 43.0788 2.87071 41.5757C2 39.8668 2 37.6298 2 33.1557V14.7818Z" fill="white"/>
                <path d="M14 18.7727C14 18.9546 14.0681 19 14.2264 19C19.5948 19 24.9627 19 30.3306 19C30.489 19 30.5798 18.9546 30.6705 18.8182C31.3952 17.75 32.1426 16.7046 32.8673 15.6364C33.2299 15.1136 33.592 14.5909 34 14C33.8412 14 33.7509 14 33.6601 14C27.2049 14 20.727 14 14.2718 14C14.0908 14 14.0227 14.0227 14.0227 14.2273C14.0227 15.75 14.0227 17.25 14 18.7727Z" fill="#00AEEF"/>
                <path d="M14.2938 33C15.989 33 17.6837 33 19.3562 33C22.9494 33 26.5199 33 30.113 33C30.4517 33 30.6554 32.9082 30.8587 32.6101C31.4011 31.7844 31.9662 30.9816 32.5308 30.1789C33.0053 29.4679 33.5029 28.7798 34 28.0229C33.9095 28.0229 33.8868 28 33.8415 28C27.3108 28 20.7571 28 14.2259 28C14 28 14 28.0918 14 28.2523C14 29.7431 14 31.211 14 32.7018C14 32.9541 14.0679 33 14.2938 33Z" fill="#00AEEF"/>
                <path d="M14.0226 25.7121C14.0226 25.9215 14.0678 26 14.2482 26C19.4157 26 24.5606 26 29.7281 26C29.8864 26 29.9764 25.9477 30.0668 25.8168C30.6762 24.7958 31.2852 23.8011 31.8946 22.8063C32.2554 22.2304 32.6166 21.6283 33 21C32.5036 21 32.0976 21 31.669 21C25.8921 21 20.1156 21 14.316 21C14.0904 21 14 21.0262 14 21.3142C14.0452 22.7801 14.0452 24.2461 14.0226 25.7121Z" fill="#00AEEF"/>
              </g>
              <defs>
                <filter id="filter0_ii_7728_12766" x="-0.0542822" y="-8.17808" width="48" height="67.0741" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="10.8995"/>
                  <feGaussianBlur stdDeviation="5.44974"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_7728_12766"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="-8.17461"/>
                  <feGaussianBlur stdDeviation="5.44974"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.0109722 0 0 0 0 0.100067 0 0 0 0 0.329167 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="effect1_innerShadow_7728_12766" result="effect2_innerShadow_7728_12766"/>
                </filter>
                <filter id="filter1_ii_7728_12766" x="-0.421387" y="-9.1122" width="47.9319" height="69.1937" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="12.1496"/>
                  <feGaussianBlur stdDeviation="7.5935"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.9875 0 0 0 0 0.9875 0 0 0 0 0.9875 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_7728_12766"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="-9.1122"/>
                  <feGaussianBlur stdDeviation="7.5935"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.0478575 0 0 0 0 0.0449132 0 0 0 0 0.0541667 0 0 0 0.1 0"/>
                  <feBlend mode="normal" in2="effect1_innerShadow_7728_12766" result="effect2_innerShadow_7728_12766"/>
                </filter>
                <linearGradient id="paint0_linear_7728_12766" x1="-0.0542826" y1="55.4965" x2="47.9457" y2="-21.0035" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#0056FB"/>
                  <stop offset="1" stop-color="#6F85FA"/>
                </linearGradient>
                <linearGradient id="paint1_linear_7728_12766" x1="26.6313" y1="42.3246" x2="19.0816" y2="9.79525" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint2_linear_7728_12766" x1="45.9366" y1="7.93769" x2="13.3528" y2="34.2723" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint3_linear_7728_12766" x1="4.10865" y1="6.09498" x2="34.6176" y2="21.7027" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <clipPath id="clip0_7728_12766">
                  <rect width="48" height="48" fill="white"/>
                </clipPath>
              </defs>
            </svg>
        `,
    classIdentifier: 'dfa-freight-forwarders-network',
    linkUrl: '/benefits/digital-tools/cargoes-flow',
  },
  {
    id: 4,
    heading: 'Ship Schedules',
    description: `We allow you to check shipping schedules by routing, by port, by vessel, or by carrier.`,
    icon: `
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
              <g clip-path="url(#clip0_7728_12748)">
                <g filter="url(#filter0_ii_7728_12748)">
                  <path d="M-0.0542603 15.9965C-0.0542603 10.396 -0.0542603 7.59576 1.03567 5.45665C1.99441 3.57503 3.52421 2.04522 5.40583 1.08649C7.54495 -0.00344849 10.3452 -0.00344849 15.9457 -0.00344849H31.9457C37.5463 -0.00344849 40.3465 -0.00344849 42.4856 1.08649C44.3673 2.04522 45.8971 3.57503 46.8558 5.45665C47.9457 7.59576 47.9457 10.396 47.9457 15.9965V31.9966C47.9457 37.5971 47.9457 40.3973 46.8558 42.5365C45.8971 44.4181 44.3673 45.9479 42.4856 46.9066C40.3465 47.9966 37.5463 47.9966 31.9457 47.9966H15.9457C10.3452 47.9966 7.54495 47.9966 5.40583 46.9066C3.52421 45.9479 1.99441 44.4181 1.03567 42.5365C-0.0542603 40.3973 -0.0542603 37.5971 -0.0542603 31.9966V15.9965Z" fill="url(#paint0_linear_7728_12748)"/>
                </g>
                <path d="M23.1728 31.19L14.8352 17.0108C14.0424 16.7636 8.80284 17.4826 14.7556 28.9037L19.1732 37.2443C19.2841 37.4538 19.5841 37.4597 19.7015 37.2546L23.1728 31.19Z" fill="url(#paint1_linear_7728_12748)"/>
                <path d="M31.5833 16.8574L23.1542 31.1563C23.3345 31.9597 26.5831 36.0928 33.5966 25.278L38.6859 17.3156C38.8131 17.1165 38.6701 16.8574 38.4331 16.8574L31.5833 16.8574Z" fill="url(#paint2_linear_7728_12748)"/>
                <path d="M14.7931 16.9922L31.6174 16.8276C32.23 16.2714 33.9362 11.5839 20.9699 10.9775L11.4707 10.5953C11.2319 10.5857 11.0783 10.8457 11.2019 11.0503L14.7931 16.9922Z" fill="url(#paint3_linear_7728_12748)"/>
                <path d="M31.6194 16.827L23.1665 31.1888L14.7905 16.9867L31.6194 16.827Z" fill="white"/>
                <g filter="url(#filter1_ii_7728_12748)">
                  <path d="M1.57568 15.1424C1.57568 10.6684 1.57568 8.43134 2.44639 6.72247C3.21229 5.21931 4.4344 3.9972 5.93756 3.23131C7.64642 2.3606 9.88346 2.3606 14.3575 2.3606H32.7314C37.2055 2.3606 39.4425 2.3606 41.1513 3.23131C42.6545 3.9972 43.8766 5.21931 44.6425 6.72247C45.5132 8.43134 45.5132 10.6684 45.5132 15.1424V33.5163C45.5132 37.9904 45.5132 40.2274 44.6425 41.9363C43.8766 43.4394 42.6545 44.6615 41.1513 45.4274C39.4425 46.2981 37.2055 46.2981 32.7314 46.2981H14.3575C9.88346 46.2981 7.64642 46.2981 5.93756 45.4274C4.4344 44.6615 3.21229 43.4394 2.44639 41.9363C1.57568 40.2274 1.57568 37.9904 1.57568 33.5163V15.1424Z" fill="url(#paint4_linear_7728_12748)"/>
                </g>
                <rect x="16.7921" y="13.863" width="3.18492" height="3.15817" rx="1.3605" fill="#7D8080"/>
                <rect x="31.131" y="13.863" width="3.18492" height="3.15817" rx="1.3605" fill="#7D8080"/>
                <rect opacity="0.7" x="14.2062" y="16.3889" width="22.3326" height="18.4735" rx="1.3605" fill="url(#paint5_linear_7728_12748)"/>
                <path d="M14.1657 17.7446C14.2685 17.0741 14.8495 16.5819 15.5278 16.5905L35.0119 16.839C35.7989 16.849 36.4132 17.5228 36.3507 18.3074L35.3212 31.232C35.2649 31.9392 34.6745 32.4844 33.965 32.4844H13.4891C12.6555 32.4844 12.0179 31.7416 12.1444 30.9176L14.1657 17.7446Z" fill="url(#paint6_linear_7728_12748)"/>
                <path d="M13.8793 17.4002C14.0377 16.804 14.5773 16.3889 15.1943 16.3889H34.9577C35.8031 16.3889 36.4438 17.1517 36.2978 17.9843L34.4184 28.7061C34.3043 29.3569 33.7391 29.8317 33.0783 29.8317H12.3472C11.4539 29.8317 10.803 28.9854 11.0322 28.122L13.8793 17.4002Z" fill="url(#paint7_linear_7728_12748)"/>
                <g filter="url(#filter2_ii_7728_12748)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M31.5332 2.59291H15.5559C12.7228 2.59291 10.7321 2.59465 9.17879 2.72156C7.6515 2.84634 6.74446 3.08094 6.04308 3.43831C4.58362 4.18195 3.39703 5.36853 2.6534 6.82799C2.29603 7.52937 2.06143 8.43641 1.93665 9.9637C1.80974 11.517 1.808 13.5077 1.808 16.3408V32.3181C1.808 35.1512 1.80974 37.1419 1.93665 38.6952C2.06143 40.2225 2.29603 41.1295 2.6534 41.8309C3.39703 43.2904 4.58362 44.477 6.04308 45.2206C6.74446 45.578 7.6515 45.8126 9.17879 45.9373C10.7321 46.0643 12.7228 46.066 15.5559 46.066H31.5332C34.3663 46.066 36.357 46.0643 37.9103 45.9373C39.4376 45.8126 40.3446 45.578 41.046 45.2206C42.5055 44.477 43.692 43.2904 44.4357 41.8309C44.7931 41.1295 45.0277 40.2225 45.1524 38.6952C45.2793 37.1419 45.2811 35.1512 45.2811 32.3181V16.3408C45.2811 13.5077 45.2793 11.517 45.1524 9.9637C45.0277 8.43641 44.7931 7.52937 44.4357 6.82799C43.692 5.36853 42.5055 4.18195 41.046 3.43831C40.3446 3.08094 39.4376 2.84634 37.9103 2.72156C36.357 2.59465 34.3663 2.59291 31.5332 2.59291ZM0.667001 5.81587C-0.421387 7.95195 -0.421387 10.7482 -0.421387 16.3408V32.3181C-0.421387 37.9107 -0.421387 40.707 0.667001 42.843C1.62437 44.722 3.15201 46.2496 5.03096 47.207C7.16704 48.2954 9.96332 48.2954 15.5559 48.2954H31.5332C37.1258 48.2954 39.922 48.2954 42.0581 47.207C43.9371 46.2496 45.4647 44.722 46.4221 42.843C47.5105 40.707 47.5105 37.9107 47.5105 32.3181V16.3408C47.5105 10.7482 47.5105 7.95195 46.4221 5.81587C45.4647 3.93692 43.9371 2.40929 42.0581 1.45191C39.922 0.363525 37.1258 0.363525 31.5332 0.363525H15.5559C9.96332 0.363525 7.16704 0.363525 5.03096 1.45191C3.15201 2.40929 1.62437 3.93692 0.667001 5.81587Z" fill="url(#paint8_linear_7728_12748)"/>
                </g>
              </g>
              <defs>
                <filter id="filter0_ii_7728_12748" x="-0.0542603" y="-8.17806" width="48" height="67.0741" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="10.8995"/>
                  <feGaussianBlur stdDeviation="5.44974"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_7728_12748"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="-8.17461"/>
                  <feGaussianBlur stdDeviation="5.44974"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.0109722 0 0 0 0 0.100067 0 0 0 0 0.329167 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="effect1_innerShadow_7728_12748" result="effect2_innerShadow_7728_12748"/>
                </filter>
                <filter id="filter1_ii_7728_12748" x="1.57568" y="-5.80241" width="43.9376" height="62.9846" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="10.884"/>
                  <feGaussianBlur stdDeviation="6.80251"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.9875 0 0 0 0 0.9875 0 0 0 0 0.9875 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_7728_12748"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="-8.16301"/>
                  <feGaussianBlur stdDeviation="6.80251"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.0478575 0 0 0 0 0.0449132 0 0 0 0 0.0541667 0 0 0 0.1 0"/>
                  <feBlend mode="normal" in2="effect1_innerShadow_7728_12748" result="effect2_innerShadow_7728_12748"/>
                </filter>
                <filter id="filter2_ii_7728_12748" x="-0.421387" y="-8.74867" width="47.9319" height="69.1937" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="12.1496"/>
                  <feGaussianBlur stdDeviation="7.5935"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.9875 0 0 0 0 0.9875 0 0 0 0 0.9875 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_7728_12748"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="-9.1122"/>
                  <feGaussianBlur stdDeviation="7.5935"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.0478575 0 0 0 0 0.0449132 0 0 0 0 0.0541667 0 0 0 0.1 0"/>
                  <feBlend mode="normal" in2="effect1_innerShadow_7728_12748" result="effect2_innerShadow_7728_12748"/>
                </filter>
                <linearGradient id="paint0_linear_7728_12748" x1="-0.0542606" y1="55.4965" x2="47.9457" y2="-21.0035" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#0056FB"/>
                  <stop offset="1" stop-color="#6F85FA"/>
                </linearGradient>
                <linearGradient id="paint1_linear_7728_12748" x1="26.6313" y1="42.3247" x2="19.0816" y2="9.79528" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint2_linear_7728_12748" x1="45.9367" y1="7.93775" x2="13.3528" y2="34.2723" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint3_linear_7728_12748" x1="4.10867" y1="6.09501" x2="34.6176" y2="21.7028" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint4_linear_7728_12748" x1="45.5132" y1="1.07295" x2="-1.02793" y2="46.2981" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#595D5D"/>
                  <stop offset="1" stop-color="#252829"/>
                </linearGradient>
                <linearGradient id="paint5_linear_7728_12748" x1="21.617" y1="12.2596" x2="58.964" y2="69.3034" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#D3D0D0"/>
                  <stop offset="1" stop-color="#4F4F4F" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint6_linear_7728_12748" x1="2.50371" y1="36.7523" x2="35.5263" y2="-6.54371" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#A5A5A5"/>
                  <stop offset="1" stop-color="#949797"/>
                </linearGradient>
                <linearGradient id="paint7_linear_7728_12748" x1="26.6944" y1="21.5646" x2="36.7565" y2="-9.99213" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="#9B9B9B" stop-opacity="0.83"/>
                </linearGradient>
                <linearGradient id="paint8_linear_7728_12748" x1="2.07506" y1="4.85714" x2="63.5637" y2="55.8977" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFF967"/>
                  <stop offset="0.359375" stop-color="#FFAA29"/>
                  <stop offset="0.791667" stop-color="#FF55CF"/>
                  <stop offset="1" stop-color="#0053EC"/>
                </linearGradient>
                <clipPath id="clip0_7728_12748">
                  <rect width="48" height="48" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          `,
    classIdentifier: 'dfa-support',
    linkUrl: 'https://www.searates.com/services/schedules',
  },
  {
    id: 5,
    heading: 'SeaRates ERP Tool',
    description: `A highly cost-effective and fully integrated Freight management software solution for all Freight Forwarders.`,
    icon: `
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
              <g clip-path="url(#clip0_7730_605)">
                <g filter="url(#filter0_ii_7730_605)">
                  <path d="M38 0H9.99998C4.47714 0 0 4.47714 0 9.99998V38C0 43.5229 4.47714 48 9.99998 48H38C43.5229 48 48 43.5229 48 38V9.99998C48 4.47714 43.5229 0 38 0Z" fill="url(#paint0_linear_7730_605)"/>
                </g>
                <path d="M37.9892 23.9987C39.0938 24.001 40 23.1046 40 22V17.2361C40 16.4785 39.572 15.786 38.8944 15.4472L26.8944 9.44721C25.5646 8.78231 23.997 9.75888 24.0061 11.2456C24.081 23.3978 25.0648 23.9716 37.9892 23.9987Z" fill="url(#paint1_linear_7730_605)"/>
                <path d="M24.0123 35.9971C23.9932 37.8751 23.9837 38.814 24.5707 39.407C25.1577 40 26.1051 40 28 40L29.5279 40C30.7307 40 31.3321 40 31.8154 39.7013C32.2987 39.4026 32.5677 38.8647 33.1056 37.7888L37.1056 29.7888C38.4268 27.1464 39.0874 25.8251 38.4717 24.8675C37.856 23.9098 36.4126 23.9612 33.5256 24.064C25.0362 24.3663 24.1127 26.0964 24.0123 35.9971Z" fill="white"/>
                <path d="M10.0107 24.0013C8.90617 23.999 8 24.8954 8 26L8 30.7639C8 31.5215 8.42801 32.214 9.10557 32.5528L21.1056 38.5528C22.4354 39.2177 24.003 38.2411 23.9938 36.7544C23.9189 24.6022 22.9352 24.0284 10.0107 24.0013Z" fill="url(#paint2_linear_7730_605)"/>
                <path d="M23.9877 12.0028C24.0068 10.1249 24.0163 9.18596 23.4293 8.59298C22.8423 8 21.8949 8 20 8H18.4721C17.2693 8 16.6679 8 16.1846 8.2987C15.7013 8.5974 15.4323 9.13531 14.8944 10.2111L10.8944 18.2111C9.57319 20.8536 8.91258 22.1748 9.52828 23.1325C10.144 24.0902 11.5874 24.0388 14.4744 23.936C22.9638 23.6337 23.8873 21.9036 23.9877 12.0028Z" fill="white"/>
              </g>
              <defs>
                <filter id="filter0_ii_7730_605" x="0" y="-3.91667" width="48" height="55.8333" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="3.91667"/>
                  <feGaussianBlur stdDeviation="1.95833"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.9875 0 0 0 0 0.9875 0 0 0 0 0.9875 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_7730_605"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="-3.91667"/>
                  <feGaussianBlur stdDeviation="2.44792"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.0774653 0 0 0 0 0.0888854 0 0 0 0 0.191667 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="effect1_innerShadow_7730_605" result="effect2_innerShadow_7730_605"/>
                </filter>
                <linearGradient id="paint0_linear_7730_605" x1="6" y1="48" x2="26.9845" y2="-1.45143" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#868EF9"/>
                  <stop offset="1" stop-color="#09D1C5"/>
                </linearGradient>
                <linearGradient id="paint1_linear_7730_605" x1="20.2461" y1="32.6353" x2="24.3727" y2="-1.42855" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint2_linear_7730_605" x1="4.24615" y1="48.6353" x2="8.37273" y2="14.5714" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <clipPath id="clip0_7730_605">
                  <rect width="48" height="48" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          `,
    classIdentifier: 'dfa-payment-protection',
    linkUrl: '/benefits/digital-tools/searates-erp',
  },
  // {
  //   id: 6,
  //   heading: 'Brand Reliability',
  //   description: `Leading logistics providers worldwide underpin our commitment to world-class standards.`,
  //   icon: `
  //           <svg
  //               xmlns="http://www.w3.org/2000/svg"
  //               width="24"
  //               height="24"
  //               viewBox="0 0 24 24"
  //               fill="white"
  //           >
  //               <path d="M2.46151 8.56444L11.5828 12.7022C11.8491 12.8232 12.1502 12.8232 12.4165 12.7022L21.5378 8.56444C22.1859 8.27037 22.1859 7.29104 21.5378 6.99697L12.4169 2.85881C12.2861 2.79902 12.1439 2.76807 12.0001 2.76807C11.8562 2.76807 11.714 2.79902 11.5832 2.85881L2.46151 6.99658C1.81345 7.29065 1.81345 8.27037 2.46151 8.56444ZM21.5382 12.0213L19.2636 10.9902L12.9342 13.8593C12.6381 13.9936 12.3237 14.0617 11.9999 14.0617C11.676 14.0617 11.362 13.9936 11.0656 13.8593L4.73655 10.9902L2.46151 12.0213C1.81345 12.3149 1.81345 13.2939 2.46151 13.5876L11.5828 17.7222C11.8491 17.8428 12.1502 17.8428 12.4165 17.7222L21.5382 13.5876C22.1863 13.2939 22.1863 12.3149 21.5382 12.0213ZM21.5382 17.0256L19.2722 15.9985L12.9342 18.8715C12.6381 19.0058 12.3237 19.0739 11.9999 19.0739C11.676 19.0739 11.362 19.0058 11.0656 18.8715L4.72794 15.9985L2.46151 17.0256C1.81345 17.3193 1.81345 18.2982 2.46151 18.5919L11.5828 22.7265C11.8491 22.8471 12.1502 22.8471 12.4165 22.7265L21.5382 18.5919C22.1863 18.2982 22.1863 17.3193 21.5382 17.0256Z"/>
  //           </svg>`,
  //   classIdentifier: 'dfa-service-reliability',
  //   linkUrl: '/benefits/brand-reliability',
  // },
];
