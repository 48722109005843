import { ReactNode } from 'react';
import dynamic from 'next/dynamic';

const Header = dynamic(() => import('components/Header'));
const Footer = dynamic(() => import('components/Footer'));

import { Provider } from 'react-redux';
import { store } from 'redux/store';

interface IProps {
  children: ReactNode;
  isHeaderTempFix?: boolean;
  withFooter?: boolean;
}

export default function RootLayout({ children, isHeaderTempFix = false, withFooter = true }: IProps) {
  return (
    <Provider store={store}>
      <div className="app">
        <Header isHeaderTempFix={isHeaderTempFix} />

        <main className="app__main">{children}</main>

        {withFooter && <Footer />}
      </div>
    </Provider>
  );
}
